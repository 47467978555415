<template>
  <div :class="Number(countTime.split(':')[0]) < 24 ? 'activeClass' : ''">
    {{ countTime }}
  </div>
</template>

<script>
import {
  // onMounted,
  // onUnmounted,
  // ref,
  toRefs,
  reactive,
  computed,
} from 'vue';

export default {
  name: 'countTime',
  props: {
    initValue: {
      type: Object,
      default: () => ({}),
    },
    initTick: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const state = reactive({
      activityClass: false,
    });

    const countTime = computed(() => {
      // 创建时间的时间戳
      let beginTime = props.initValue.beginTime;
      let currentTime = '';
      if (typeof beginTime === 'string') {
        currentTime = Number(beginTime);
      }
      // console.log('beginTime创建时间戳', beginTime);
      // 过期时间的具体数值 例如：48小时 24小时
      let expirationHour = props.initValue.expirationHour;

      //  计算过期时间的时间戳：  创建时间+过期时间 48小时是动态传递
      const targetTime = currentTime + expirationHour * 60 * 60 * 1000;
      // 剩余时间 = 目标倒计时时间戳-当前时间戳
      const remainingTime = targetTime - props.initTick;
      if (remainingTime >= 0) {
        let h = Math.floor(remainingTime / (1000 * 60 * 60));
        let m = Math.floor((remainingTime / (1000 * 60)) % 60);
        let s = Math.floor((remainingTime / 1000) % 60);
        let hours = h < 10 ? '0' + h : h;
        let minutes = m < 10 ? '0' + m : m;
        let seconds = s < 10 ? '0' + s : s;
        return `${hours}:` + `${minutes}:` + `${seconds}`;
      } else {
        return '00:00:00';
      }
    });

    return {
      ...toRefs(state),
      props,
      countTime,
    };
  },
};
</script>

<style scoped>
.activeClass {
  color: #e74c3c;
}
</style>
