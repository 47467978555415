<template>
  <div class="iss-main-grid">
    <div class="iss-search-wrap">
      <search-form :items="items" @fnSearch="handleFnSearch" />
    </div>
    <div class="iss-grid-wrap mt-20">
      <grid
        ref="gridRef"
        :allow-selection="true"
        :columns="columns"
        :code="$route.path"
        :url="url"
        :url-params="{ distributeType: 1 }"
        :search="search"
        :btn-operation="btnOperation"
        :scroll="{ x: 1200, y: gridHeight }"
      >
        <template #operation="{ record }">
          <operation :options="options" :record="record" />
        </template>
        <template #scoreLevel="{ text }">
          <span class="score-level">{{ scoreLevel[text] }}</span>
        </template>
        <template #claimStatus="{ text }">
          <span v-if="text === 0">未领取</span>
          <span v-if="text === 1">已领取</span>
        </template>
        <template #recoveryMode="{ text }">
          <span v-if="text === 0">手动回收</span>
          <span v-if="text === 1">自动回收</span>
        </template>
        <template #distributionMethod="{ text }">
          <span v-if="text === 0">手动分发</span>
          <span v-if="text === 1">自动分发</span>
        </template>
        <!--        -->
        <template #expirationTime="{ record }">
          <span>
            <!--          getTime   record -->
            <count-time :init-value="record" :init-tick="currentTime" />
          </span>
        </template>
      </grid>
    </div>
  </div>
  <pt-modal
    v-model:visible="visible"
    :init-value="activeItem"
    @fnOk="handleFnOk"
  />
</template>

<script>
import { reactive, ref, toRefs, onMounted, onUnmounted } from 'vue';
import SearchForm from '@/components/searchForm';
import Grid from '@/components/grid';
import Operation from '@/components/operation';
import distributionApi from '@/api/distribution';
import dictionaryApi from '@/api/dictionary.js';
import ptModal from '@/views/opportunity/components/distribution/ptModal';
import { message } from 'ant-design-vue';
import countTime from '@/components/countTime';

export default {
  components: {
    SearchForm,
    Grid,
    Operation,
    ptModal,
    countTime,
    // ATag: Tag,
  },
  setup() {
    const gridRef = ref();
    const hours = ref(0);
    const minutes = ref(0);
    const seconds = ref(0);

    const state = reactive({
      visible: false,
      gridSelectedRowKeys: [],
      search: {},
      TagList: [],
      twentyFour: 0,
      activeItem: {
        length: '',
        gridIds: [],
        distributeType: 2,
      },
      countdown: '',
      currentTime: null,
      getTime: new Date().getTime(),
    });

    onMounted(() => {
      const timer = setInterval(() => {
        // 获取当前时间戳，以毫秒为单位
        state.currentTime = new Date().getTime();
      }, 1000);

      onUnmounted(() => {
        clearInterval(timer);
      });
    });

    return {
      gridRef,
      hours,
      minutes,
      seconds,
      ...toRefs(state),
      scoreLevel: { high: '高分', middle: '中分', low: '低分' },
      items: [
        { key: 'contactName', label: '商机姓名' },
        { key: 'mobileNum', label: '手机' },
        { key: 'company', label: '公司' },
        {
          key: 'leadPoolId',
          label: '线索池',
          type: 'select',
          valueKey: 'leadPoolId',
          labelKey: 'leadPoolName',
          showSearch: true,
          filterOption: 'label',
          url: distributionApi.clueUrl,
        },
        // {
        //   key: 'industryLv1Code',
        //   label: '行业',
        //   type: 'select',
        //   valueKey: 'itemNameCn',
        //   labelKey: 'itemNameCn',
        //   showSearch: true,
        //   url: dictionaryApi.industryLv1CodeUrl,
        // },
        { key: 'industryLv1Code', label: '行业' },
        {
          key: 'promoteUserId',
          label: '归属人',
          type: 'select',
          valueKey: 'id',
          labelKey: 'userName',
          showSearch: true,
          filterOption: 'label',
          url: distributionApi.marketingUserListUrl,
        },
        {
          key: 'scoreLevel',
          label: '所属分阶',
          type: 'select',
          dataset: [
            { label: '全部', value: '' },
            { label: '高', value: 'high' },
            { label: '中', value: 'middle' },
          ],
        },
        {
          key: 'city',
          label: '城市',
          type: 'select',
          valueKey: 'itemNameCn',
          labelKey: 'itemNameCn',
          showSearch: true,
          url: dictionaryApi.cityUrl,
        },
        {
          key: 'distributionMethod',
          label: '分发方式',
          type: 'select',
          dataset: [
            { label: '手动分发', value: '0' },
            { label: '自动分发', value: '1' },
          ],
        },
        {
          key: 'recoveryMode',
          label: '回收方式',
          type: 'select',
          dataset: [
            { label: '手动回收', value: '0' },
            { label: '自动回收', value: '1' },
          ],
        },
        {
          key: 'claimStatus',
          label: '领取状态',
          type: 'select',
          dataset: [
            { label: '已领取', value: '1' },
            { label: '未领取', value: '0' },
          ],
        },
      ],
      columns: [
        {
          dataIndex: 'contactName',
          title: '商机姓名',
          width: 120,
          ellipsis: true,
        },
        {
          dataIndex: 'expirationTime',
          title: '过期时间',
          width: 120,
          ellipsis: true,
          slots: { customRender: 'expirationTime' },
        },
        { dataIndex: 'mobileNum', title: '手机', width: 140, ellipsis: true },
        { dataIndex: 'city', title: '城市', width: 120, ellipsis: true },
        { dataIndex: 'company', title: '公司', width: 180, ellipsis: true },
        {
          dataIndex: 'leadPoolName',
          title: '线索池',
          width: 200,
          ellipsis: true,
        },
        {
          dataIndex: 'scoreLevel',
          title: '所属分阶',
          slots: { customRender: 'scoreLevel' },
          width: 120,
          ellipsis: true,
        },
        {
          dataIndex: 'promoteUserName',
          title: '商机归属人',
          width: 120,
          ellipsis: true,
        },
        // {
        //   dataIndex: 'createTime',
        //   title: '分发时间',
        //   width: 180,
        //   ellipsis: true,
        // },
        {
          dataIndex: 'industryLv1Code',
          title: '行业',
          width: 100,
          ellipsis: true,
        },
        {
          dataIndex: 'claimStatus',
          title: '领取状态',
          width: 120,
          ellipsis: true,
          slots: { customRender: 'claimStatus' },
        },
        {
          dataIndex: 'recoveryMode',
          title: '回收方式',
          width: 120,
          ellipsis: true,
          slots: { customRender: 'recoveryMode' },
        },
        {
          dataIndex: 'distributionMethod',
          title: '分发方式',
          width: 120,
          ellipsis: true,
          slots: { customRender: 'distributionMethod' },
        },
      ],
      btnOperation: [
        {
          type: 'redistribution',
          label: '重新分发',
          permission: 'bind:redistribution',
          fnClick: selectedRowKeys => {
            const length = selectedRowKeys.length;
            if (length) {
              state.length = length;
              state.gridSelectedRowKeys = selectedRowKeys;
              state.activeItem.length = selectedRowKeys.length;
              state.activeItem.gridIds = selectedRowKeys;
              state.visible = true;
            } else {
              message.info('请先选择数据');
            }
          },
        },
      ],
      options: [],
      url: distributionApi.batchedPageUrl,
      gridHeight: document.body.clientHeight - 419,

      handleFnSearch: value => {
        const temp = Object.assign({}, value);
        // temp.tag = temp.tag?.join(',');
        state.search = temp;
      },
      handleFnOk: value => {
        state.visible = value;
        gridRef.value.refreshGrid();
      },
    };
  },
};
</script>

<style lang="less" scoped>
.iss-main-grid {
  max-height: calc(100vh - 155px);
  overflow-y: auto;
}
.score-level {
  padding: 2px 8px;
  color: #1a46f9;
  border-radius: 2px;
  border: 1px solid #1a46f9;
}
</style>
