<template>
  <a-tabs v-model:activeKey="activeKey">
    <a-tab-pane key="1" tab="未分发" />
    <a-tab-pane key="2" tab="已分发" />
    <a-tab-pane key="3" tab="不分发" />
    <a-tab-pane key="4" tab="已过期" />
    <a-tab-pane key="5" tab="已完成" />
  </a-tabs>
  <div>
    <template v-if="activeKey === '1'">
      <iss-pane-one></iss-pane-one>
    </template>
    <template v-else-if="activeKey === '2'">
      <iss-pane-two></iss-pane-two>
    </template>
    <template v-else-if="activeKey === '3'">
      <iss-pane-three></iss-pane-three>
    </template>
    <template v-else-if="activeKey === '4'">
      <iss-pane-four></iss-pane-four>
    </template>
    <template v-else-if="activeKey === '5'">
      <iss-pane-five></iss-pane-five>
    </template>
  </div>
</template>

<script>
import { reactive, toRefs, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { Tabs } from 'ant-design-vue';
import IssPaneOne from './components/distribution/paneOne';
import IssPaneTwo from './components/distribution/paneTwo';
import IssPaneThree from './components/distribution/paneThree';
import IssPaneFour from './components/distribution/paneFour';
import IssPaneFive from './components/distribution/paneFive';

export default {
  name: 'distribution',
  components: {
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
    IssPaneOne,
    IssPaneTwo,
    IssPaneThree,
    IssPaneFour,
    IssPaneFive,
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const listRef = ref();
    const state = reactive({
      activeKey: '1',
      messageCount: {},
    });
    return { listRef, ...toRefs(state), route, store };
  },
};
</script>
